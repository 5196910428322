@charset "UTF-8";

.btn-back {
  padding: 15px 40px;
  background: #fff;
  border: 2px solid #5959ad;
  color: #333;
}
.content-tag {
  color: #5959ad;
  display: inline-block;
  padding: 10px 30px;
  border: 2px solid #eee;
  border-bottom: 0;
  background: #fff;
}
.content-editor {
  border: 2px solid #eee;
  background: #fff;
  padding: 15px 30px;
}
.widget-category {
  margin-bottom: 20px;
}
.widget-category .side-nav li a {
  font-size: 18px;
  color: #333;
}
.widget-category .side-nav li a:hover {
  color: #4db7fe;
  font-weight: normal;
  text-decoration: none;
}
.widget-category h3 {
  display: inline-block;
  padding-bottom: 8px;
  border-bottom: 1px solid #000;
  margin-top: 40px;
}
.flex-divider {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-divider span {
  text-align: center;
  padding: 10px 15px;
  font-size: 30px;
  font-weight: 500;
  color: #333;
  max-width: 80%;
}

.vendor-list .vendor-item{
  margin-top: 40px;
  margin-bottom: 40px;
}

.vendor-item a.vendor-title {
  display: block;
  text-align: center;
  padding: 15px;
  background-color: #f6f6f6;
}
.vendor-item a.vendor-title:hover,
.vendor-item a.vendor-title:active,
.vendor-item a.vendor-title:focus{
  text-decoration: none;
}
.vendor-item .vendor-title h3 {
  display: inline;
  font-size: 18px;
  line-height: 1.8;
  margin: 0;
  position: relative;
  border-bottom: 1px solid #999;
  padding-bottom: 5px;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.same-address {
  padding: 3px 10px;
  color: brown;
  border: 1px solid;
  margin-left: 10px;
}
.relative {
  position: relative;
}

.apply .sub_menu {
  margin: 60px 0;
}
.sub_menu ul {
  text-align: center;
}
.sub_menu li {
  color: #333;
  display: inline-block;
  list-style-type: none;
  padding: 20px;
  font-size: 17px;
  letter-spacing: 1px;
}
.sub_menu ul {
  padding: 0;
  border-top: 1px #ddd solid;
  border-bottom: 1px #ddd solid;
}

.sub_menu li.active {
  background-color: #5B5CAE;
  color: #f8f8f8;
}

.recruit {
  margin: 0 150px;
}

.recruit .form-control {
  margin-bottom: 20px;
}

.recruit h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.recruit ul.invoice {
  list-style: none;
  padding: 0;
}

.recruit ul.invoice li {
  margin-bottom: 15px;
}

.recruit.success p{
  margin: 30px auto;
  font-size: 20px;
  font-weight: bold;
}

.recruit.success i{
  color: #5B5CAE;
}

label .form-control {
  display: inline-block;
  width: auto;
}

.recruit label {
  letter-spacing: 1px;
}
@media (min-width: 481px) {
    .vendor-list .vendor-item{
        margin-left: -5px;
        margin-right: -5px;
    }

    .flex-divider:before,
    .flex-divider:after{
        flex: 1;
        content: ' ';
        margin: 10px 0;
        border-bottom: 2px solid #5959ad;
    }
}
@media (max-width: 480px) {
  .flex-divider span {
    max-width: 100%;
  }
  .vendor-list .vendor-item{
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .sub_menu li {
    display: block;
  }
  .recruit {
    margin: 0 15px;
  }
}

.memberlist-index {
  margin-top: 5px;
  margin-bottom: 30px;
  padding-top: 30px;
  text-align: center;
  position: relative;
}

.memberlist-container button {
  padding: 8px 35px;
  background: #15a2d8;
  border: 2px solid #FECE0A;
  border-radius: 5px;
  letter-spacing: 1px;
  font-size: 20px;
  font-weight: bold;
  color: #545a84;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.memberlist-container button:hover {
  color: #4b4b69;
  background: #fff267;
}


/*.pageTop {
    position: fixed;
    right: 0;
    bottom: 120px;
    z-index: 9;
    padding: 1px 3px;
    background: rgba(50, 50, 50, .5);
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    &:hover {
        background: rgba(70, 70, 70, .7);
        color: #fff;
    }
}*/

.top-banner img {
  border-bottom: 2px solid #E8BE5A;
}

.top-banner .page-header {
  margin: 20px auto 10px;
  text-align: center;
  font-weight: bold;
  font-family: '微軟正黑體';
  color: #888;
}

.top-news {
  margin-top: 5px;
  padding-top: 30px;
  padding-bottom: 20px;
  background-color: #f7f7f7;
}

.top-news .row {
  width: 90%;
  margin: 0 auto;
}

.top-news h1 {
  position: relative;
  text-align: center;
  font-weight: bold;
  font-size: 36px;
  color: #0077f9;
}

.top-news h1:before {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100px;
  border-top: 2px solid #8E558C;
}

.top-news h2 {
  position: relative;
}

.top-news h1 span {
  font-size: 20px;
  font-family: arial;
  font-weight: normal;
  margin: 0 10px;
}

.top-news .section-header {
  margin: 30px 0;
}

.join {
  color: blue
}

.top-news .seemore {
  position: absolute;
  right: 7%;
  top: 0;
}
.top-news #mobile-view {
  display: none;
}
.top-news .seemore a {
  background: #acd43f;
  color: #5c5cae;
  font-size: 18px;
  padding: 10px 20px;
  border: 1px #5c5cae solid;
  border-radius: 5px;
}

.top-news a {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.top-news a.know-more {
  border: none;
  color: white;
  background-color: #00A3DF;
  border-radius: 5px;
  padding: 7px 20px;
}

.news-list1 {
  padding-left: 30px;
  padding-right: 30px;
}

.news .news-list1 {
    padding-left: 20px;
    padding-right: 30px;
}

.news-list .news-list1 {
    margin-bottom: 50px;
}

.media-heading {
  font-size: 25px;
  line-height: 1.3em;
  margin-bottom: 15px;
  padding-left: 10px;
  height: 65px;
  border-left: 4px solid #f4da14;
  overflow: hidden;
}
.media-heading a {
  color: #333;
}
.media-heading a:hover {
  color: #5c5cae;
  text-decoration: none;

}

.news .media-heading {
  font-size: 22px;
  height: 80px;
}


.top-news a.seemore1 {
  display: block;
  width: 35px;
  height: 35px;
  line-height: 30px;
  text-align: center;
  color: #5c5cae;
  border: 2px solid #5c5cae;
  border-radius: 50%; 
  margin: 20px auto 0;
}

.top-news a.seemore1:hover {
  color: #555;
  border: 2px solid #555;
  -ms-transform: scale(1.15, 1.15);
  -webkit-transform: scale(1.15, 1.15);
  transform: scale(1.15, 1.15);
  transform-origin: 50% 50%;
}

.news a.seemore1 {
  display: block;
  padding-left: 5px 10px;
  line-height: 30px;
  text-align: center;
  color: #5c5cae;
  border: 2px solid #5c5cae;
  margin: 20px auto 30px;
  font-weight: bold;

  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.news a.seemore1:hover {
  color: #fff;
  background-color: #c9a050;
  border: 2px solid #c9a050;
}

.top-news .news-col3, .news .news-col3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 72px;
}


.top-news .news-ad img {
  width: 100%;
}


.news-detail {
  padding-left: 0;
}
.news-detail .media-heading {
  border-left: none;
  padding-left: 0;
  font-size: 20px;
}
.news-detail p {
  margin-bottom: 25px;
}

.news-detail p > a {
  text-decoration: underline;
}

.news-detail figure {
  margin-bottom: 35px;
}






@media (max-width: 767px) {
.top-news .seemore {
  position: absolute;
  right: 0;
  top: 0;
}
.top-news .row {
  width: 100%;
}
}





.memberlist-container {
  margin: 30px auto 50px;
}

.memberlist-container h2, .top-news h2 {
  margin-bottom: 30px;
  color: #333;
  position: relative;
  text-align: center;
}

.memberlist-container h2:after, .top-news h2:after {
  content: '';
  width: 50px;
  border-bottom: 2px solid #333;
  display: block;
  margin: 10px auto 0;
}

.memberlist-container img {
  margin: 10px auto 20px;
  display: block;
}




@media (min-width: 768px) {
.top-news h1 button {
    margin-left: 30px;
    margin-right: -100px;
  }
}

.top-news .news_area {
  position: relative;
}

.top-news .news_area:before, .top-news .news_area:after {
  content: '';
  position: absolute;
  bottom: 0;
  z-index: 0;
}

.top-news .news_area:before {
  top: 0;
  right: 0;
  width: 50%;
  background: #e3ecf3;
}

@media (max-width: 767px) {
  .top-news .news_area:before {
    right: 0;
    bottom: 0;
    width: 100%;
    background: #e3ecf3;
  }
}

.top-news .news_area:after {
  right: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent transparent #e3ecf3 transparent;
}

@media (min-width: 1200px) {
  .top-news .news_area:after {
    border-width: 0 0 346px 60vh;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .top-news .news_area:after {
    border-width: 0 0 288px 60vh;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .top-news .news_area:after {
    border-width: 0 0 275px 60vh;
  }
}

.top-news .news_area .news_box {
  z-index: 1;
}

.top-news .news_area .news_img {
  margin: 15px 10px;
  box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.7);
}

.top-news .news_area .news_img img {
  width: 100%;
  max-height: 100%;
}

.top-news .news_area .text_area {
  padding: 30px 10px;
  height: 285px;
  line-height: 2;
  font-size: 16px;
  color: #000;
}

@media (min-width: 1200px) {
  .top-news .news_area .text_area {
    height: 345px;
  }
}

@media (max-width: 991px) {
  .top-news .news_area .text_area {
    height: 275px;
  }
}

@media (max-width: 767px) {
  .top-news .news_area .text_area {
    padding: 15px;
    max-height: 480px;
  }
}

.top-news .news_area .text_area .text {
  height: 100%;
  overflow: hidden;
}

.other-links {
  margin-top: 50px;
}

.other-links .fb_shareBox {
  margin: 0 auto;
  width: 340px;
}

@media (max-width: 767px) {
  .other-links .fb_shareBox {
    margin-top: 30px;
  }
}

@media (min-width: 768px) {
  .other-links .fb_center {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 767px) {
  /*.height_outer {
    margin: 10px 0; }*/
  .top-news .seemore a {
    display: none;
  }
  .top-news #mobile-view {
    display: block;
  }
  .itemBox {
    float: left;
    width: 50%;
  }
  .itemBox .item {
    width: 100%;
    height: 100%;
    padding-top: 70%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }
  .box:after {
    content: '';
    width: 100%;
    display: block;
    clear: both;
  }

  .itemBox p {
    font-size: 18px;
    color: #3a5c73;
    position: absolute;
    top: 10%;
    left: 12%;
    text-shadow: #fff 0.1em 0.1em 0.2em;
  }

  .itemBox:nth-child(2) p, .itemBox:nth-child(4) p, .itemBox:nth-child(6) , .itemBox:nth-child(8) p { left: auto; right:12%;}
}